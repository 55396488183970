
@import 'node_modules/bootstrap/scss/functions';
@import "./color";
@import './_variables.scss';
@import './components.scss';



html,
body {
  height: 100%;
  -webkit-overflow-scrolling: touch;

  font-family: 'Open Sans', sans-serif !important;
}

body {
  overflow-x: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

p {
  margin-bottom: 0;
}

// utilities
.pointer {
  cursor: pointer;
  box-shadow: none;
}

.btn:focus {
  outline: none;
}

.btn-primary:focus,
.btn-info:focus {
  box-shadow: none;
}

.hide {
  opacity: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.p-small {
  font-size: 0.8rem;
}
.p-mini {
  font-size: 0.65rem;
}

.item-shadow {
  //box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.11);
  //background: white;

  border-radius: 3px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background: white;
}

.group-item {
  //boxShadow: '0 1px 0 rgba(0,0,0,.25)'
  border-radius: 3px;
  background: white;
  //box-shadow: 0px 2px 1px 1px rgba(0, 28, 36, 0.15);
  //box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.18);
  border: 1px solid #ddd;
  //border-left: 1px solid rgb(234, 237, 237, 0.1);
  //border-right: 1px solid rgb(234, 237, 237, 0.1);
}

.alpha-80 {
  opacity: 0.8;
}
