$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
        'blue': $blue,
        'indigo': $indigo,
        'purple': $purple,
        'pink': $pink,
        'red': $red,
        'orange': $orange,
        'yellow': $yellow,
        'green': $green,
        'teal': $teal,
        'cyan': $cyan,
        'white': $white,
        'gray': $gray-600,
        'gray-dark': $gray-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: #4a6fa5 /*$blue*/ !default;

$secondary: #006daa !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value') !default;
// scss-docs-end theme-colors-rgb

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
        'blue-100': $blue-100,
        'blue-200': $blue-200,
        'blue-300': $blue-300,
        'blue-400': $blue-400,
        'blue-500': $blue-500,
        'blue-600': $blue-600,
        'blue-700': $blue-700,
        'blue-800': $blue-800,
        'blue-900': $blue-900,
) !default;

$indigos: (
        'indigo-100': $indigo-100,
        'indigo-200': $indigo-200,
        'indigo-300': $indigo-300,
        'indigo-400': $indigo-400,
        'indigo-500': $indigo-500,
        'indigo-600': $indigo-600,
        'indigo-700': $indigo-700,
        'indigo-800': $indigo-800,
        'indigo-900': $indigo-900,
) !default;

$purples: (
        'purple-100': $purple-200,
        'purple-200': $purple-100,
        'purple-300': $purple-300,
        'purple-400': $purple-400,
        'purple-500': $purple-500,
        'purple-600': $purple-600,
        'purple-700': $purple-700,
        'purple-800': $purple-800,
        'purple-900': $purple-900,
) !default;

$pinks: (
        'pink-100': $pink-100,
        'pink-200': $pink-200,
        'pink-300': $pink-300,
        'pink-400': $pink-400,
        'pink-500': $pink-500,
        'pink-600': $pink-600,
        'pink-700': $pink-700,
        'pink-800': $pink-800,
        'pink-900': $pink-900,
) !default;

$reds: (
        'red-100': $red-100,
        'red-200': $red-200,
        'red-300': $red-300,
        'red-400': $red-400,
        'red-500': $red-500,
        'red-600': $red-600,
        'red-700': $red-700,
        'red-800': $red-800,
        'red-900': $red-900,
) !default;

$oranges: (
        'orange-100': $orange-100,
        'orange-200': $orange-200,
        'orange-300': $orange-300,
        'orange-400': $orange-400,
        'orange-500': $orange-500,
        'orange-600': $orange-600,
        'orange-700': $orange-700,
        'orange-800': $orange-800,
        'orange-900': $orange-900,
) !default;

$yellows: (
        'yellow-100': $yellow-100,
        'yellow-200': $yellow-200,
        'yellow-300': $yellow-300,
        'yellow-400': $yellow-400,
        'yellow-500': $yellow-500,
        'yellow-600': $yellow-600,
        'yellow-700': $yellow-700,
        'yellow-800': $yellow-800,
        'yellow-900': $yellow-900,
) !default;

$greens: (
        'green-100': $green-100,
        'green-200': $green-200,
        'green-300': $green-300,
        'green-400': $green-400,
        'green-500': $green-500,
        'green-600': $green-600,
        'green-700': $green-700,
        'green-800': $green-800,
        'green-900': $green-900,
) !default;

$teals: (
        'teal-100': $teal-100,
        'teal-200': $teal-200,
        'teal-300': $teal-300,
        'teal-400': $teal-400,
        'teal-500': $teal-500,
        'teal-600': $teal-600,
        'teal-700': $teal-700,
        'teal-800': $teal-800,
        'teal-900': $teal-900,
) !default;

$cyans: (
        'cyan-100': $cyan-100,
        'cyan-200': $cyan-200,
        'cyan-300': $cyan-300,
        'cyan-400': $cyan-400,
        'cyan-500': $cyan-500,
        'cyan-600': $cyan-600,
        'cyan-700': $cyan-700,
        'cyan-800': $cyan-800,
        'cyan-900': $cyan-900,
) !default;
// fusv-enable
